#header h1.title {
  display: flex;
  align-items: center;
  font-size: 3em;
  margin: 1em;
  padding-bottom: 1em;
  border-bottom: #c00430 solid 2px;
}

#header img {
  margin-bottom: 12px;
  margin-right: 1em;
}

#header .intro {
  font-size:  1.3em;
  margin: 2em;
  margin-bottom: 3em;
}

@media (max-width: 600px) {
  #header h1.title {
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
   #header img {
     margin-bottom: 1em;
   }
}
