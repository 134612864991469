#footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  color: #777;
  margin-top: 2em;
}

#footer>* {
  margin: 2em;
}

#footer .logo a {
  color: #777;
}

#footer .logo {
  width: 200px;
  display: flex;
  flex-direction: column;
}

#footer .logo>* {
  max-height: 100%;
}

#footer .disclaimer, #footer .contact {
  text-align: justify;
  max-width: 450px;
}
