.templateContainer {
  padding: 1em 0;
}

.templateContainer.article {
  min-height: 500px;
  font-size: 18px;
  line-height: 1.58;
  font-style: normal;
  color: rgba(0, 0, 0, 0.84);
  font-weight: 400;
}

.templateContainer a {
  color: rgb(49, 114, 221);
  text-decoration: none;
  font-weight: 400;
}

.teamplateHeader {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
}

@media print {
  @page {
    margin: 4em;
  }

}
