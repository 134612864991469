.questionnaireActionBar {
  text-align: right;
}

.questionnaireActionBar button {
  text-transform: none;
}

@media (max-width: 600px) {
  .questionnaireActionBar {
    text-align: center;
  }
}
