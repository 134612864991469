.subscribe_container {
  max-width: 700px;
  border-top: #aaa solid 1px;
  padding: 1em 0;
}

.subscribe_container .emailInput{
  flex-grow: 2;
  min-width: 250px;
}

.subscribe_container button {
  margin-left: 1em;
  flex-grow: 1;
  background-color: rgba(192, 4, 48, 1)
}

.subscribe_container .inputContainer {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.subscribe_container .inputContainer > * {
  /*margin-bottom: 1em;*/
}

@media (max-width: 600px) {
  .subscribe_container button {
    margin-left: 0;
    margin-top: 1em;
    width: 100%;
  }

  .subscribe_container .inputContainer {
  }
}
